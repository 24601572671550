<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="taxModal"
    :title="$t(typeModal) + ' ' + $t('Tax Profile')"
    :buttons-hidden="true"
    autocomplete="nofill">
    <div class="px-4 mb-6">
      <p class="text-darkgray text-base text-center mb-8">{{ $t('Tax profile only accepts english letters') }}</p>
      <div class="grid grid-cols-1 my-4">
        <div class="col-span-1">
          <v-select :disabled="typeModal === 'Edit'" v-validate="'required'" class="w-full" name="type" v-model="type" :placeholder="$t('Tax Profile Type') + '*'" label="name" :options="types"/>
          <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4" v-if="type && type.value === 'company'">
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.name" v-validate="{required: true, regex: /^[a-zA-Z0-9() -'.?!,:;_]*$/ }" :label-placeholder="$t('Legal Name') + '*'" name="legal name"/>
          <span class="text-danger text-sm" v-show="errors.has('legal name')">{{ tax.name ? $t('The legal name') + $t(' field only accepts english letters') : $t('The legal name') + $t(' field is required') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.tax_registration_number" v-validate="{required: true, regex: /^[0-9]*$/ }" :label-placeholder="$t('Tax Registration Number') + '*'" name="tax registration number"/>
          <span class="text-danger text-sm" v-show="errors.has('tax registration number')">{{ tax.tax_registration_number ? $t('The tax registration number only accepts english numbers') : $t('The tax registration number') + $t(' field is required') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.governorate" v-validate="{required: true, regex: /^[a-zA-Z0-9() -'.?!,:;_]*$/ }" :label-placeholder="$t('Governorate') + '*'" name="governorate"/>
          <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ tax.governorate ? $t('The governorate') + $t(' field only accepts english letters') : $t('The governorate') + $t(' field is required') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.city" v-validate="{required: true, regex: /^[a-zA-Z0-9() -'.?!,:;_]*$/ }" :label-placeholder="$t('City') + '*'" name="city"/>
          <span class="text-danger text-sm" v-show="errors.has('city')">{{ tax.city ? $t('The city') + $t(' field only accepts english letters') : $t('The city') + $t(' field is required') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.street" v-validate="{required: true, regex: /^[a-zA-Z0-9() -'.?!,:;_]*$/ }" name="street" :label-placeholder="$t('Street') + '*'" />
          <span class="text-danger text-sm" v-show="errors.has('street')">{{ tax.street ? $t('The street') + $t(' field only accepts english letters') : $t('The street') + $t(' field is required') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.building_number" v-validate="{required: true, regex: /^[a-zA-Z0-9() -'.?!,:;_]*$/ }" name="building number" :label-placeholder="$t('Building Number') + '*'" />
          <span class="text-danger text-sm" v-show="errors.has('building number')">{{ tax.building_number ? $t('The building number') + $t(' field only accepts english letters') : $t('The building number') + $t(' field is required') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4" v-else-if="type && type.value === 'person'">
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.name" v-validate="{required: true, regex: /^[a-zA-Z0-9() -'.?!,:;_]*$/ }" :label-placeholder="$t('Full Name') + '*'" name="full name"/>
          <span class="text-danger text-sm" v-show="errors.has('full name')">{{ tax.name ? $t('The full name') + $t(' field only accepts english letters') : $t('The full name') + $t(' field is required') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.national_id" v-validate="'required|numeric|min:14|max:14'" :label-placeholder="$t('National ID') + '*'" name="national id"/>
          <span class="text-danger text-sm" v-show="errors.has('national id')">{{ errors.first('national id') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.governorate" :label-placeholder="$t('Governorate')" name="governorate"/>
          <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.city" :label-placeholder="$t('City')" name="city"/>
          <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.street" name="street" :label-placeholder="$t('Street')" />
          <span class="text-danger text-sm" v-show="errors.has('street')">{{ errors.first('street') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input class="w-full" v-model="tax.building_number" name="building number" :label-placeholder="$t('Building Number')" />
          <span class="text-danger text-sm" v-show="errors.has('building number')">{{ errors.first('building number') }}</span>
        </div>
      </div>
      <div v-if="type">
        <input type="file" @input="onSelectFile" class="hidden" ref="fileInput" accept="image/jpg, image/jpeg, application/pdf">
        <div
          @click="$refs.fileInput.click()"
          @drop="handleDrop"
          @dragover="handleDragover"
          @dragenter="handleDragover"
          class="w-4/5 m-auto mt-10 mb-2 px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
          <div v-if="images.length === 0">
            <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
            <span>{{$t('Drop Image or')}} </span>
            <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
          </div>
          <div v-else>
            <img v-for="image in images" :key="image.index" class="mr-4 w-20 h-20" :src="image"/>
          </div>
        </div>
        <span class="text-danger text-sm" v-if="clicks > 0 && images.length === 0">This field is required</span>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-8">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button v-if="typeModal === 'Add'" @click="addTax" class="active-btn btn">{{ $t('Add') }}</button>
        <button v-else @click="updateTax" class="active-btn btn">{{ $t('Save') }}</button>
      </div>
    </div>
  </shipblu-prompt>
</template>
<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData'

export default {
  props: ['taxModal', 'typeModal', 'taxData'],
  data () {
    return {
      type: '',
      tax: {},
      images: [],
      types: [
        {
          name: 'Company',
          value: 'company'
        },
        {
          name: 'Person',
          value: 'person'
        }
      ],
      sentImages: [],
      clicks: 0
    }
  },
  watch: {
    taxModal () {
      if (this.typeModal === 'Edit') {
        this.tax = this.taxData.company ? this.taxData.company : this.taxData.person
        this.type = this.taxData.company ? {name: 'Company', value: 'company'} : {name: 'Person', value: 'person'}
        this.images = this.taxData.documents
      }
    }
  },
  methods: {
    onSelectFile (event) {
      const [file] = this.$refs.fileInput.files
      if (file) {
        this.images.push(URL.createObjectURL(file))
        this.sentImages.push(event.target.files[0])
      }
    },
    changeImage (taxID) {
      const formData = new FormData()
      this.sentImages.forEach(item => {
        formData.append('documents', item)
      })
      sendRequest(false, false, this, `api/v1/accounting/tax-profiles/${taxID}/file-upload/`, 'post', formData, true,
        () => {
        }
      )
    },
    closeModal () {
      this.$validator.pause()
      this.type = ''
      this.clicks = 0
      this.images = []
      this.$emit('tax', {})
      this.$emit('taxModal', false)
      this.$emit('loadTaxProfile')
    },
    addTax () {
      this.clicks++
      this.$validator.validateAll().then(result => {
        if (result && this.images.length > 0) {
          const taxObj = {
            type: this.type.value,
            name: this.tax.name,
            governorate: this.tax.governorate !== '' ? this.tax.governorate : null,
            city: this.tax.city !== '' ? this.tax.city : null,
            street: this.tax.street ? this.tax.street : null,
            building_number: this.tax.building_number ? this.tax.building_number : null,
            tax_registration_number: this.tax.tax_registration_number ? this.tax.tax_registration_number : null,
            national_id: this.tax.national_id ? this.tax.national_id : null
          }
          sendRequest(false, false, this, 'api/v1/accounting/tax-profiles/', 'post', taxObj, true,
            (response) => {
              this.changeImage(response.data.id)
              if (this.$route.query.tab === 'upfront') {
                this.upfrontSubcribtion()
              }
              this.closeModal()
            }
          )
        }
      })
    },
    upfrontSubcribtion () {
      sendRequest(false, false, this, 'api/v1/merchants/request-upfront/', 'post', {}, true,
        (response) => {
          const merchant = JSON.parse(localStorage.getItem('merchant'))
          merchant.upfront_status = 'requested'
          localStorage.removeItem('merchant')
          localStorage.setItem('merchant', JSON.stringify(merchant))
          this.$vs.notify({
            color:'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
          this.$emit('upfrontRequested', true)
        }
      )
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isImage(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .png, .jpg suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
    },
    isImage (file) {
      return /\.(jpeg|jpg|pdf)$/.test(file.name)
    },
    updateTax () {
      this.clicks++
      this.$validator.validateAll().then(result => {
        if (result && this.images.length > 0) {
          const taxObj = {
            type: this.type.value,
            name: this.tax.name,
            governorate: this.tax.governorate ? this.tax.governorate : null,
            city: this.tax.city ? this.tax.city : null,
            street: this.tax.street ? this.tax.street : null,
            building_number: this.tax.building_number ? this.tax.building_number : null,
            tax_registration_number: this.tax.tax_registration_number ? this.tax.tax_registration_number : null,
            national_id: this.tax.national_id ? this.tax.national_id : null
          }
          sendRequest(false, false, this, `api/v1/accounting/tax-profiles/${this.taxData.id}/`, 'patch', taxObj, true,
            () => {
              this.closeModal()
            }
          )
        }
      })
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  }
}
</script>
<style scoped>
.text-darkgray {
  color: #475C6F;
}
</style>